import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"

export default () => {
  useEffect(() => {
    navigate("/giotrif/patient/")
  })

  return <Layout seoTitle="Patient Enrollment"></Layout>
}
